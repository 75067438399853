h1 {
  font-size: 26px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

.main {
  font-size: 18px;
  min-height: 750px;
  padding: 0px 15px 50px 10px;
  text-align: left;
  box-sizing: border-box;
  line-height: 1.5;

}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nav {
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.boardImg {
  background-repeat: no-repeat;
  width:100%;
  max-width: 500px;
  height: 347px;
  margin: 20px auto;
  color:white;
  font-size:2em;
  padding-top:1.5em;
  text-align:center;
}


.linksPart {
  margin: 20px auto;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.linksPart p{
  margin-top:0px;
}

.LinkDes {
  padding: 5px;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  font-size: 0.8em;
}

.divNumberStyle {
  background-color: coral;
  color: white;
  padding: 5px 5px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.divNumberStylePos {
  background-color: green;
  color: white;
  padding: 5px 5px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.divNumberStyleTip {
  font-size: 10px;
  padding-left: 5px;
}

.dividingNumbers {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.calc {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 5px;
  padding: 1em 1em 1em 2em;
  background-color: aliceblue;
  border-radius: 10px;
  line-height: 1;
  border:1px solid black;
  margin: 1em;
}

.rules {
  padding: 1em;
  background-color: aliceblue;
  border-radius: 10px;
  line-height: 1.5;
  margin: 1em;
  font-style: italic;
  border: 1px solid cornflowerblue;
}

.calcInput {
  width: 200px;
  height: 30px;
  margin-right: 15px;
  font-size: 20px;
  text-align: center;
  border-radius: 5px;
}

.calcBtn {
  width: 200px;
  /* height: 30px; */
  font-size: 20px;
  font-weight: bold;
  background-color: #00a591;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 10px 0px;
  border-radius: 5px;
  margin-top: 1em;

}

.centerHeader {
  text-align: center;
}

.spanRight {
  font-weight: bold;
  color: green;
  border-bottom: 5px solid lightgreen;
  padding: 0px 5px;
}

.spanWrong {
  font-weight: bold;
  color: red;
  background-color: lightgoldenrodyellow;
  border-bottom: 5px solid lightcoral;
  padding: 0px 5px;
  /* border-radius: 10px; */
}


h1 {
  background: darkblue;
  color: white;
  padding: 10px 0px 10px 20px;
  border-radius: 10px;
  text-align: center;
}

h2 {
  background: darkorange;
  color: white;
  padding: 5px 0px 5px 20px;
  border-radius: 10px;
}

h3 {
  background: darkcyan;
  color: white;
  padding-left: 20px;
  border-radius: 10px;
}

.numberInTitle {
  font-size: 1.2em;
  color: yellow;
  padding: 0px 5px;
}

.smallF {
  font-size: 0.6em;
}

@media only screen and (max-width: 530px) {
  .linksPart {
    grid-template-columns: repeat(1, 1fr);
  }
  .boardImg {
    background-repeat: no-repeat;
    background-size: cover;
  }
}


table, th, td {
  border: 1px solid;
}

table {
  border-collapse: collapse;
  width:100%;
  font-size: 0.8em;
}

td{
  padding-left: 5px;
}

/* .simpleTable{
  border:none !important;
}

.treeTable{
  width:fit-content;
  border-collapse: collapse;
  font-size: 0.8em;
  border:none;
}
#primeTreeTable th, td{
  border:none;
} */
.primeTreeEnpty{
  height:45px;
  width:45px;
  border:none;
}
.primeTreeEnptySpace{
  height:45px;
  border:none;
}

.primeTree2{
    margin: 20px auto;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    text-align: center;
    font-size:11px;
}

.primeTreeCellFirst2{
  width:30px;
  height:30px;
  text-align: center;
}

.primeTreeCell2{
  width:30px;
  height:30px;
  text-align: center;
}
.primeTreeArrowCell{
  width:30px;
  height:30px;
}
.primeTreeCell{
  width:30px;
  height:30px;
  text-align: center;
  font-size:13px;
  
}

.blue{
  border:1px solid blue;
  border-bottom-right-radius: 25%;
  border-bottom-left-radius: 25%;
  background: lightblue;

}

.red{
  border:1px solid red;
  background: lightpink;
  border-bottom-right-radius: 25%;
  border-bottom-left-radius: 25%;
}
.first{
  border:1px solid green;
  background: greenyellow;
  border-bottom-right-radius: 25%;
  border-bottom-left-radius: 25%;
}